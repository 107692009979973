import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";

const db = getFirestore();

export class notificationService {
  static async insertNotification(data) {
    let result = {
      code: 1,
      status: "success",
      message: "新增推播成功",
    };

    try {
      await setDoc(doc(db, "notifications", data.id), data);
    } catch (exception) {
      result.code = 0;
      result.status = exception.name;
      result.message = exception.message;
    }

    return result;
  }
}
