<template>
  <div>
    <h2>推播功能</h2>
    <form @submit.prevent="insertNotification()">
      <div class="card mb-5">
        <div class="card-body">
          <div class="mb-3">
            <label class="form-label">推播內容</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="notification.message"
            />
          </div>

          <div class="mb-3">
            <label class="form-label">推播時間</label>
            <div class="input-group mb-3">
              <input
                type="date"
                required
                class="form-control"
                v-model="datetime.date"
              />
            </div>

            <div class="input-group mb-3">
              <input
                type="time"
                required
                class="form-control"
                v-model="datetime.time"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label">推播成員</label>
            <div>
              <input
                class="form-check-input"
                type="checkbox"
                @click="insertSelectableMemberIntoSelectedMemberList"
                v-model="isSeleteAllMemberFlag"
              />
              <label class="form-check-label"> 全部成員 </label>
            </div>
          </div>

          <label class="form-label">服務店家字詞包含</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="請輸入服務店家字詞"
              v-model="storeKeyWord"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="selectAllMemberByStoreKeyWord"
              >
                一次加入
              </button>
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label">選擇成員（可多選，可搜尋）</label>

            <Multiselect
              required
              mode="tags"
              placeholder="請選擇成員"
              :close-on-select="false"
              :searchable="true"
              :object="true"
              :disabled="isSeleteAllMemberFlag"
              :options="selectableMemberOptionList"
              :Remove="clearAllMultiSelectMemberArray"
              @Remove="clearAllMultiSelectMemberArray"
              :key="selectableMemberOptionList"
              :taggable="true"
              v-model="multiSelectMemberArray"
            >
            </Multiselect>
          </div>

          <div class="mb-3 div-flex-right">
            <label class="form-label"></label>
            <button
              type="button"
              @click="clearAllMultiSelectMemberArray"
              class="btn btn-danger save-button me-1"
            >
              清除成員
            </button>
            <button type="submit" class="btn btn-info save-button">儲存</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { variableService } from "../../../services/variableService.js";
import { notificationService } from "../../../services/notificationService.js";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      now: new Date(),
      multiSelectMemberArray: [],
      isSeleteAllMemberFlag: false,
      selectableMemberOptionList: [],
      selectableMemberOptionListWithStoreKey: [],
      searchMemberDocId: [],
      storeKeyWord: "",
      addMemberNumber: 1,
      selectedMemberNumber: 1,
      selectedMemberList: [],
      notification: {
        id: "",
        message: "",
        isSendFlag: 0,
        sendingDatetime: "",
        sendingTargetMemberIdList: [],
      },
      datetime: {
        date: new Date().toISOString().slice(0, 10),
        time: new Date().getHours() + ":" + new Date().getMinutes(),
      },
    };
  },
  methods: {
    //  選擇符合服務店家字詞的成員
    selectAllMemberByStoreKeyWord() {
      if (this.storeKeyWord.length <= 0) {
        this.triggerSweetAlert("warning", "請輸入服務店家字詞", "timer");
      } else {
        let selectableMemberOptionList = this.selectableMemberOptionList;
        let storeKeyWord = this.storeKeyWord;
        let selectedMemberMatchStoreKeyWord = [];
        let storeName = "";
        selectableMemberOptionList.forEach((memberData) => {
          if (memberData.label.split("，")[3]) {
            storeName = memberData.label.split("，")[3];
          } else if (memberData.label.split("，")[2]) {
            storeName = memberData.label.split("，")[2];
          } else {
            storeName = "";
          }
          if (storeName !== "") {
            let keyWordMatchFlag = storeName.indexOf(storeKeyWord);
            if (keyWordMatchFlag !== -1) {
              selectedMemberMatchStoreKeyWord.push(memberData);
            }
          }
        });
        if (selectedMemberMatchStoreKeyWord.length === 0) {
          this.triggerSweetAlert("warning", "無符合成員", "timer");
        } else {
          selectedMemberMatchStoreKeyWord.forEach((memberData) => {
            this.multiSelectMemberArray.push(memberData);
          });
          console.log();
          console.log("multiSelectMemberArray");
          console.log(this.multiSelectMemberArray);
          console.log("=====================================");
          console.log("selectedMemberMatchStoreKeyWord");
          console.log(selectedMemberMatchStoreKeyWord);
          // this.multiSelectMemberArray.concat(selectedMemberMatchStoreKeyWord);

          console.log("multiSelectMemberArray");
          console.log(this.multiSelectMemberArray);

          // this.multiSelectMemberArray = selectedMemberMatchStoreKeyWord;
        }
      }
    },

    //  勾選全部成員
    insertSelectableMemberIntoSelectedMemberList() {
      if (this.isSeleteAllMemberFlag === false) {
        this.multiSelectMemberArray = this.selectableMemberOptionList;
      }
    },

    //  警告視窗
    async triggerSweetAlert(icon, message, type) {
      try {
        let swal;
        switch (type) {
          case "confirm":
            swal = {
              cancelButtonText: "返回上一步",
              showCancelButton: true,
              icon: icon,
              text: message,
              confirmButtonColor: "#1c8554",
              confirmButtonText: "確認發送",
            };
            break;

          case "info":
            swal = {
              icon: icon,
              text: message,
              confirmButtonText: "確認",
            };
            break;
          case "timer":
            swal = {
              icon: icon,
              title: message,
              showConfirmButton: false,
              timer: 1500,
            };
            break;

          default:
            swal = {
              icon: icon,
              text: message,
              confirmButtonText: "確認",
            };
            break;
        }

        const result = await new Swal(swal);
        return result;
      } catch (error) {
        console.error(error);
      }
    },

    // 儲存推播
    async insertNotification() {
      let multiSelectMemberArray = this.multiSelectMemberArray;
      let notificationData = this.notification;

      if (multiSelectMemberArray.length === 0) {
        this.triggerSweetAlert("error", "請至少選擇一名成員", "timer");
        return;
      }

      let confirmInsertNotification = await this.triggerSweetAlert(
        "warning",
        "是否確認以上會員之推播訊息",
        "confirm"
      );
      if (confirmInsertNotification.isConfirmed === true) {
        multiSelectMemberArray.forEach((memberData) => {
          let memberId = memberData.value;
          notificationData.sendingTargetMemberIdList.push(memberId);
        });

        notificationData.id =
          String(this.now.getTime()) + this.getRandomIntByMultiplesAsString(6);
        notificationData.sendingDatetime = this.mixSendingDateTime();

        const insertNotificationResult =
          await notificationService.insertNotification(notificationData);

        if (insertNotificationResult.code === 1) {
          this.triggerSweetAlert(
            "success",
            insertNotificationResult.message,
            "timer"
          );
        } else {
          this.triggerSweetAlert(
            "error",
            insertNotificationResult.message,
            "timer"
          );
        }
      } else {
        return;
      }

      // 重置表格
      this.datetime = {
        date: new Date().toISOString().slice(0, 10),
        time: new Date().getHours() + ":" + new Date().getMinutes(),
      };
      this.notification = {
        id: "",
        message: "",
        isSendFlag: 0,
        sendingDatetime: "",
        sendingTargetMemberIdList: [],
      };
      this.multiSelectMemberArray = [];
      this.isSeleteAllMemberFlag = false;
      this.storeKeyWord = "";
    },

    // 以輸入數字的位數取得亂數，並以輸出字串型態
    getRandomIntByMultiplesAsString(digit) {
      let result = "";
      let addNumber = "";
      for (let i = 0; i < digit; i++) {
        addNumber = String(Math.floor(Math.random() * 10));
        result += addNumber;
      }
      return String(result);
    },

    // 計算推播日期
    mixSendingDateTime() {
      let sendDateTimeString = "";

      if (this.datetime.date !== null && this.datetime.time !== null) {
        let hourAndMinuteString = this.datetime.time;
        let timeString = hourAndMinuteString + ":00";
        sendDateTimeString = this.datetime.date + " " + timeString;
      }

      return sendDateTimeString;
    },
    clearAllMultiSelectMemberArray() {
      console.log("!!!!!!!!!!!!!!!!!!!!");
      this.multiSelectMemberArray = [];
    },
  },
  computed: {},
  async created() {
    // console.log("created!");

    this.selectableMemberOptionList =
      await variableService.get_allMember_selectOption(true);
  },
  updated() {
    // console.log('updated!');
  },
  // watch: {
  //   datetime: {
  //     handler(newValue) {
  //       console.log("datetime is :");
  //       console.log(newValue);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.div-flex-right {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-direction: row;
}

.save-button {
  float: right;
  color: white;
}

table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

tr.delete {
  background-color: #f8d7da;
}

.icon-right {
  margin-right: 0;
}
</style>
